export default {
  path: '/',
  name: 'dataHome',
  component: () => import(/* webpackChunkName: "layout" */'@/views/layout'),
  // meta: {
  //     keepAlive:true,
  //     title: 'dataHome',
  //     icon: 'data'
  // },
  children: [
    {
      path: '/data',
      name: 'dataManage',
      component: () => import(/* webpackChunkName: "data" */'@/views/data'),
      // redirect: '/data/waterLatest',
      meta: {
        title: 'dataManage',
        icon: 'dataManage',
        parent: '/dataManage',
        userRouter: true,
        index: 1,
      },
      children: [
          /** 最新数据 */
        {
          path: '/data/waterLatest',
          name: 'waterLatest',
          component: () => import(/* webpackChunkName: "waterLatest" */'@/views/data/main/showTable/waterData/waterLatest'),
          meta: {
            keepAlive: true,
            title: 'waterLatest',
            icon: 'data',
            parent: '/data',
            subMenu: 'waterData'
          }
        },
          /** 历史数据 */
        {
          path: '/data/waterHistory',
          name: 'waterHistory',
          component: () => import(/* webpackChunkName: "waterHistory" */'@/views/data/main/showTable/waterData/waterHistory'),
          meta: {
            keepAlive: true,
            title: 'waterHistory',
            icon: 'data',
            parent: '/data',
            subMenu: 'waterData'
          }
        },
        /** 最新数据lindorm 版本 */
        {
          path: '/data/waterNewLindorm',
          name: 'waterNewLindorm',
          component: () => import(/* webpackChunkName: "waterNewLindorm" */'@/views/data/main/showTable/waterData/waterNewLindorm.vue'),
          meta: {
            keepAlive: true,
            title: 'waterNewLindorm',
            icon: 'data',
            parent: '/data',
            subMenu: 'waterData'
          }
        },
        /** 历史数据lindom 版本 */
        {
          path: '/data/waterHistoryLindom',
          name: 'waterHistoryLindom',
          component: () => import(/* webpackChunkName: "waterHistoryLindom" */'@/views/data/main/showTable/waterData/waterHistoryLindom.vue'),
          meta: {
            keepAlive: true,
            title: 'waterHistoryLindom',
            icon: 'data',
            parent: '/data',
            subMenu: 'waterData'
          }
        },
        /** 日用水lindorm */
        {
          path: '/data/waterDayLindom',
          name: 'waterDayLindom',
          component: () => import(/* webpackChunkName: "waterDayLindom" */'@/views/data/main/showTable/waterData/waterDayLindom.vue'),
          meta: {
            keepAlive: true,
            title: 'waterDayLindom',
            icon: 'data',
            parent: '/data',
            subMenu: 'waterData'
          }
        },
        /** 月用水lindorm */
        {
          path: '/data/waterMonthLindom',
          name: 'waterMonthLindom',
          component: () => import(/* webpackChunkName: "waterMonthLindom" */'@/views/data/main/showTable/waterData/waterMonthLindom.vue'),
          meta: {
            keepAlive: true,
            title: 'waterMonthLindom',
            icon: 'data',
            parent: '/data',
            subMenu: 'waterData'
          }
        },
          /** 月用水数据 */
        {
          path: '/data/waterMonth',
          name: 'waterMonth',
          component: () => import(/* webpackChunkName: "waterMonth" */'@/views/data/main/showTable/waterData/waterMonth'),
          meta: {
            keepAlive: true,
            title: 'waterMonth',
            icon: 'data',
            parent: '/data',
            subMenu: 'waterData'
          }
        },
        /** 日用水数据 */
        {
          path: '/data/waterDay',
          name: 'waterDay',
          component: () => import(/* webpackChunkName: "waterDay" */'@/views/data/main/showTable/waterData/waterDay'),
          meta: {
            keepAlive: true,
            title: 'waterDay',
            icon: 'data',
            parent: '/data',
            subMenu: 'waterData'
          }
        },
        /** 数据统计 */
        {
          path: '/data/dataStatistics',
          name: 'dataStatistics',
          component: () => import(/* webpackChunkName: "dataStatistics" */'@/views/data/main/showTable/waterData/dataStatistics'),
          meta: {
            keepAlive: true,
            title: 'dataStatistics',
            icon: 'data',
            parent: '/data',
            subMenu: 'waterData'
          }
        },
        /** 数据统计 */
        {
          path: '/data/waterSalesRecords',
          name: 'waterSalesRecords',
          component: () => import(/* webpackChunkName: "waterSalesRecords" */'@/views/data/main/showTable/waterData/waterSalesRecords'),
          meta: {
            keepAlive: true,
            title: 'waterSalesRecords',
            icon: 'data',
            parent: '/data',
            subMenu: 'waterData'
          }
        },
        // {
        //   path: '/data/connectError',
        //   name: 'connectError',
        //   component: () => import('@/views/main/index'),
        //   meta: {
        //     keepAlive: true,
        //     title: 'connectError',
        //     icon: 'data',
        //     parent: '/data',
        //     subMenu: 'errorData'
        //   }
        // },
        /** 异常告警最新数据 */
        {
          path: '/data/dataErrorLast',
          name: 'dataErrorLast',
          component: () => import(/* webpackChunkName: "dataErrorLast" */'@/views/data/main/showTable/errorData/dataErrorLast'),
          meta: {
            keepAlive: true,
            title: 'dataErrorLast',
            icon: 'data',
            parent: '/data',
            subMenu: 'errorData'
          }
        },
        /** 异常告警数据 */
        {
          path: '/data/dataError',
          name: 'dataError',
          component: () => import(/* webpackChunkName: "dataError" */'@/views/data/main/showTable/errorData/dataError'),
          meta: {
            keepAlive: true,
            title: 'dataError',
            icon: 'data',
            parent: '/data',
            subMenu: 'errorData'
          }
        },
        /** 报警器地图显示数据 */
        {
          path: '/data/mapDisplay',
          name: 'mapDisplay',
          component: () => import(/* webpackChunkName: "mapDisplay" */'@/views/data/main/showTable/manholeCoverDate/mapDisplay'),
          meta: {
            keepAlive: true,
            title: 'mapDisplay',
            icon: 'data',
            parent: '/data',
            subMenu: 'manholeCoverMonitor'
          }
        },
        
        /** 报警器最新报警数据 */
        {
          path: '/data/manholeCoverLastAlarmData',
          name: 'manholeCoverLastAlarmData',
          component: () => import(/* webpackChunkName: "manholeCoverLastAlarmData" */'@/views/data/main/showTable/manholeCoverDate/alarmData/lastAlarmData.vue'),
          meta: {
            keepAlive: true,
            title: 'manholeCoverLastAlarmData',
            icon: 'data',
            parent: '/data',
            subMenu: 'manholeCoverMonitor'
          }
        },
        /** 报警器报警数据 */
        {
          path: '/data/manholeCoverAlarmData',
          name: 'manholeCoverAlarmData',
          component: () => import(/* webpackChunkName: "manholeCoverAlarmData" */'@/views/data/main/showTable/manholeCoverDate/alarmData/alarmData.vue'),
          meta: {
            keepAlive: true,
            title: 'manholeCoverAlarmData',
            icon: 'data',
            parent: '/data',
            subMenu: 'manholeCoverMonitor'
          }
        },
        /** 井盖报警器最新数据 */
        // {
        //   path: '/data/manholeCoverLastDate',
        //   name: 'manholeCoverLastDate',
        //   component: () => import(/* webpackChunkName: "manholeCoverLastDate" */'@/views/data/main/showTable/manholeCoverDate/manholeCoverLastDate'),
        //   meta: {
        //     keepAlive: true,
        //     title: 'manholeCoverLastDate',
        //     icon: 'data',
        //     parent: '/data',
        //     subMenu: 'manholeCoverMonitor'
        //   }
        // },
        /** 井盖报警器最新数据Lindom */
        {
          path: '/data/manholeCoverLastDateLindom',
          name: 'manholeCoverLastDateLindom',
          component: () => import(/* webpackChunkName: "manholeCoverLastDateLindom" */'@/views/data/main/showTable/manholeCoverDate/manholeCoverLastDateLindom'),
          meta: {
            keepAlive: true,
            title: 'manholeCoverLastDate',
            icon: 'data',
            parent: '/data',
            subMenu: 'manholeCoverMonitor'
          }
        },
        /** 井盖报警器历史数据 */
        // {
        //   path: '/data/manholeCoverHistoryDate',
        //   name: 'manholeCoverHistoryDate',
        //   component: () => import(/* webpackChunkName: "manholeCoverHisDate" */'@/views/data/main/showTable/manholeCoverDate/manholeCoverHisDate'),
        //   meta: {
        //     keepAlive: true,
        //     title: 'manholeCoverHistoryDate',
        //     icon: 'data',
        //     parent: '/data',
        //     subMenu: 'manholeCoverMonitor'
        //   }
        // },
        /** 井盖报警器历史数据Lindom */
        {
          path: '/data/manholeCoverHistoryDateLindom',
          name: 'manholeCoverHistoryDateLindom',
          component: () => import(/* webpackChunkName: "manholeCoverHisDateLindom" */'@/views/data/main/showTable/manholeCoverDate/manholeCoverHisDateLindom'),
          meta: {
            keepAlive: true,
            title: 'manholeCoverHistoryDate',
            icon: 'data',
            parent: '/data',
            subMenu: 'manholeCoverMonitor'
          }
        },
        /** 报警器大屏 */
        {
          path: '/data/manholeCoverDataStatistics',
          name: 'manholeCoverDataStatistics',
          component: () => import(/* webpackChunkName: "manholeCoverDataStatistics" */'@/views/data/main/showTable/manholeCoverDate/manholeCoverDataStatistics'),
          meta: {
            keepAlive: true,
            title: 'dataStatistics',
            icon: 'data',
            parent: '/data',
            subMenu: 'manholeCoverMonitor'
          }
        },
        /** 楼栋显示 */
        {
          path: '/data/buildingData',
          name: 'buildingData',
          component: () => import(/* webpackChunkName: "buildingData" */'@/views/data/main/showTable/buildingData/index.vue'),
          meta: {
            keepAlive: true,
            title: 'buildingData',
            icon: 'data',
            parent: '/data',
            subMenu: 'dataAnalysis'
          }
        },
        /** 用户详情 */
        {
          path: '/data/eqptInfo/:eqptId',
          name: 'eqptInfo',
          component: () => import(/* webpackChunkName: "eqptInfo" */'@/components/eqptInfo/eqptInfo'),
          meta: {
            keepAlive: true,
            title: 'eqptInfo',
            icon: 'data',
            parent: '/data',
            subMenu: ''
          }
        },
      ]
    },

  ]

}